import React, { useState, useEffect } from 'react'
import "twin.macro"
import { ErrorPage } from '../components/errorPage'

const Deleted = () => <ErrorPage 
	title="Deleted" 
	details={<p>The transfer you requested has been deleted.</p>}
/>

export default Deleted
